import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { format } from 'date-fns/esm';
import { Observable, of, Subject, takeUntil } from 'rxjs';
import { GENDER_OPTIONS } from 'src/app/core/helpers/global/global.constant';
import { getDifferingPropertiesArray } from 'src/app/core/helpers/global/global.util';
import {
  USER_AGENT,
  USER_VALIDATIONS,
} from 'src/app/core/helpers/global/user-management.constant';
import {
  BUTTON_ACTIONS,
  FORMAT_FOR_DATES,
  NG_SELECT_QUERIES,
} from 'src/app/core/helpers/ui/ui.constant';
import { Role } from 'src/app/core/interfaces/api/role.interface';
import { User } from 'src/app/core/interfaces/api/user.interface';
import { ModalWithAction } from 'src/app/core/interfaces/ui/bootstrap-modal.interface';
import {
  ButtonAction,
  NgSelect,
  NgSelectQuery,
} from 'src/app/core/interfaces/ui/ui.interface';
import { CityService } from 'src/app/core/services/api/city.service';
import { CommissionPlanService } from 'src/app/core/services/api/commission-plan.service';
import { CountryService } from 'src/app/core/services/api/country.service';
import { CurrencyService } from 'src/app/core/services/api/currency.service';
import { LanguageService } from 'src/app/core/services/api/language.service';
import { RoleService } from 'src/app/core/services/api/role.service';
import { UserService } from 'src/app/core/services/api/user.service';
import { BootstrapModalService } from 'src/app/core/services/ui/bootstrap-modal.service';
import { FilterService } from 'src/app/core/services/ui/filter.service';
import { SearchNgSelectService } from 'src/app/core/services/ui/search-ng-select.service';
import { ToastrNotificationService } from 'src/app/core/services/ui/toastr-notification.service';
import { strongPasswordValidator } from 'src/app/shared/validators/passwordMatch';
import { adultValidator } from 'src/app/shared/validators/validatorsAdult';
import { phoneNumberValidator } from 'src/app/shared/validators/validatorsPhoneNumber';

@Component({
  selector: 'app-agent-create-modal',
  templateUrl: './agent-create-modal.component.html',
  providers: [
    { provide: 'ngCurrencies', useClass: SearchNgSelectService },
    { provide: 'ngCountries', useClass: SearchNgSelectService },
    { provide: 'ngCities', useClass: SearchNgSelectService },
    { provide: 'ngLanguages', useClass: SearchNgSelectService },
    { provide: 'ngUsers', useClass: SearchNgSelectService },
    { provide: 'ngCommissionPlans', useClass: SearchNgSelectService },
    { provide: 'ngRoles', useClass: SearchNgSelectService },
  ],
})
export class AgentCreateModalComponent implements OnInit, OnDestroy {
  public BUTTON_ACTIONS = BUTTON_ACTIONS;
  public NG_SELECT_QUERIES = NG_SELECT_QUERIES;
  public USER_VALIDATIONS = USER_VALIDATIONS;
  public FORMAT_FOR_DATES = FORMAT_FOR_DATES;

  public countries$: Observable<NgSelect<string>[]> = of([]);
  public cities$: Observable<NgSelect<string>[]> = of([]);
  public currencies$: Observable<NgSelect<string>[]> = of([]);
  public languages$: Observable<NgSelect<string>[]> = of([]);
  public genders$: Observable<NgSelect<string>[]> = of(GENDER_OPTIONS);
  public users$: Observable<NgSelect<string>[]> = of([]);
  public commissionPlans$: Observable<NgSelect<string>[]> = of([]);
  public roles$: Observable<NgSelect<string>[]> = of([]);

  public titleModal: string = '';
  public createdAt: Date | undefined = undefined;
  public updatedAt: Date | undefined = undefined;
  public agentModalForm: FormGroup | undefined = undefined;
  public activeButtonAction: ButtonAction | undefined = undefined;

  private selectedUser: User | undefined = undefined;
  private unsubscribe$: Subject<boolean> = new Subject<boolean>();
  private defaultRoleId: string | null = null;
  showPassword: boolean = false;

  constructor(
    private _formBuilder: FormBuilder,
    private _currencyService: CurrencyService,
    private _countryService: CountryService,
    private _cityService: CityService,
    private _languageService: LanguageService,
    private _roleService: RoleService,
    private _commissionService: CommissionPlanService,
    private _bsModalService: BootstrapModalService<ModalWithAction<User>>,
    private _userService: UserService,
    private _filterService: FilterService<object>,
    private _translateService: TranslateService,
    private _notificationService: ToastrNotificationService,

    @Inject('ngCurrencies')
    private _ngCurrencies: SearchNgSelectService<string>,
    @Inject('ngCountries')
    private _ngCountries: SearchNgSelectService<string>,
    @Inject('ngCities')
    private _ngCities: SearchNgSelectService<string>,
    @Inject('ngLanguages')
    private _ngLanguages: SearchNgSelectService<string>,
    @Inject('ngUsers')
    private _ngUsers: SearchNgSelectService<string>,
    @Inject('ngCommissionPlans')
    private _ngCommissionPlans: SearchNgSelectService<string>
  ) {}

  ngOnInit(): void {
    this.setConfigNgSelects();
    this.suscribeModalDataIssued();
    this.setDefaultRoleId();
    if (this.activeButtonAction === BUTTON_ACTIONS.ADD) {
      for (let i = 0; i < 1; i++) {
        this.addReferred();
      }
    }
  }

  private setConfigNgSelects(): void {
    this._ngCurrencies.setSearchTermKey('name');
    this._ngCurrencies.setFetchDataFunction(
      this._currencyService.findCurrenciesForSelect.bind(this._currencyService)
    );
    this.currencies$ = this._ngCurrencies.getData();

    this._ngCountries.setSearchTermKey('name');
    this._ngCountries.setFetchDataFunction(
      this._countryService.findCountriesForSelect.bind(this._countryService)
    );
    this.countries$ = this._ngCountries.getData();

    this._ngCities.setSearchTermKey('name');
    this._ngCities.setFetchDataFunction(
      this._cityService.findCitiesForSelect.bind(this._cityService)
    );
    this.cities$ = this._ngCities.getData();

    this._ngLanguages.setSearchTermKey('name');
    this._ngLanguages.setFetchDataFunction(
      this._languageService.findLanguagesForSelect.bind(this._languageService)
    );
    this.languages$ = this._ngLanguages.getData();

    this._ngUsers.setSearchTermKey('username');
    this._ngUsers.setFetchDataFunction(
      this._userService.findUsersForSelect.bind(this._userService)
    );
    this.users$ = this._ngUsers.getData();

    this._ngCommissionPlans.setSearchTermKey('planName');
    this._ngCommissionPlans.setFetchDataFunction(
      this._commissionService.findCommissionPlansForSelect.bind(
        this._commissionService
      )
    );
    this.commissionPlans$ = this._ngCommissionPlans.getData();
  }

  private suscribeModalDataIssued(): void {
    this._bsModalService
      .getDataIssued()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: ({ buttonAction, selectedRow }) =>
          this.processSelectedUser(buttonAction, selectedRow),
        error: () => this.closeModal(),
      });
  }

  private processSelectedUser(buttonAction: ButtonAction, user?: User): void {
    this.activeButtonAction = buttonAction;
    this.selectedUser = user;
    this.agentModalForm = this.getConfigModalForm(this.activeButtonAction);
    this.populateModalForm(this.selectedUser);

    const executeAction = this.handleProcessButtonAction(buttonAction);
    executeAction();
  }

  private getConfigModalForm(buttonAction: ButtonAction): FormGroup {
    const defaultConfig = {
      firstName: [null, [Validators.required]],
      lastName: [null, [Validators.required]],
      documentNumber: [null, [Validators.required]],
      countryId: [null, [Validators.required]],
      cityId: [null, [Validators.required]],
      birthDate: [null, [Validators.required, adultValidator()]],
      address: [null],
      gender: [null, [Validators.required]],
      email: [null, [Validators.required, Validators.email]],
      mobileNumber: [null, [Validators.required, phoneNumberValidator()]],
      conventionalNumber: [null],
      username: [
        '',
        [Validators.required, Validators.pattern(/^[A-Za-z\d]{4,}$/)],
      ],
      languageId: [null, [Validators.required]],
      currencyId: [null],
      comissionPlanId: [null, [Validators.required]],
      roleId: [this.defaultRoleId || null, [Validators.required]],
      referred: this._formBuilder.array([]),
    };

    const addUserConfig = {
      ...defaultConfig,
      password: ['', [Validators.required, strongPasswordValidator()]],
    };

    const editUserConfig = {
      ...defaultConfig,
      state: [false],
    };

    const viewUserConfig = {
      ...defaultConfig,
      state: [false],
      TFA: [false],
    };

    const actionsMap = new Map<ButtonAction, object>([
      [BUTTON_ACTIONS.ADD, addUserConfig],
      [BUTTON_ACTIONS.EDIT, editUserConfig],
      [BUTTON_ACTIONS.VIEW, viewUserConfig],
    ]);

    const config = actionsMap.get(buttonAction) || {};

    return this._formBuilder.group(config);
  }

  private populateModalForm(user?: User): void {
    if (!user || !this.agentModalForm) return;

    const {
      firstName,
      lastName,
      documentNumber,
      birthDate,
      username,
      email,
      mobileNumber,
      conventionalNumber,
      countryId,
      cityId,
      address,
      gender,
      currencyId,
      languageId,
      comissionPlanId,
      state,
      TFA,
      createdAt,
      updatedAt,
      referred,
    } = user;

    const selectedUser = {
      firstName,
      lastName,
      birthDate: birthDate ? format(new Date(birthDate), 'yyyy-MM-dd') : null,
      documentNumber,
      username,
      email,
      mobileNumber,
      conventionalNumber,
      countryId: countryId._id,
      currencyId,
      cityId: cityId._id,
      address,
      gender,
      languageId: languageId._id,
      comissionPlanId: comissionPlanId._id,
      state: state === 1 ? true : false,
      TFA,
    };
    const referredFormArray = this.agentModalForm.get('referred') as FormArray;
    referredFormArray.clear();
    if (referred && referred.length > 0) {
      referred.forEach((ref: any) => {
        const referredGroup = this._formBuilder.group({
          referralId: [ref.referralId?._id || null],
          percentage: [
            ref.percentage || null,
            [Validators.min(0.5), Validators.max(100)],
          ],
        });
        referredFormArray.push(referredGroup);
      });
    }
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;

    this.agentModalForm.patchValue(selectedUser);
  }

  private handleProcessButtonAction(buttonAction: ButtonAction): () => void {
    const actionMap = new Map<ButtonAction, () => void>([
      [BUTTON_ACTIONS.ADD, () => this.handleAddUser()],
      [BUTTON_ACTIONS.EDIT, () => this.handleEditUser()],
      [BUTTON_ACTIONS.VIEW, () => this.handleViewUser()],
    ]);
    return actionMap.get(buttonAction) || (() => {});
  }

  private handleAddUser(): void {
    this.titleModal = 'userManagement.users.add';
    this._ngUsers.extendFilter({ type: USER_AGENT });
    this._ngCurrencies.triggerFetchData();
    this._ngCountries.triggerFetchData();
    this._ngLanguages.triggerFetchData();
    this._ngUsers.triggerFetchData();
    this._ngCommissionPlans.triggerFetchData();
  }

  private handleEditUser(): void {
    this.titleModal = 'userManagement.users.edit';

    if (!this.agentModalForm || !this.selectedUser) return;

    this.handleAddDataToStream(this.selectedUser);

    const { countryId } = this.selectedUser;
    this._ngCities.extendFilter({ countryId: countryId._id });
    this._ngUsers.extendFilter({ type: USER_AGENT });

    this._ngCurrencies.triggerFetchData();
    this._ngCountries.triggerFetchData();
    this._ngCities.triggerFetchData();
    this._ngLanguages.triggerFetchData();
    this._ngUsers.triggerFetchData();
    this._ngCommissionPlans.triggerFetchData();
  }

  private handleViewUser(): void {
    this.titleModal = 'userManagement.users.view';

    if (!this.agentModalForm || !this.selectedUser) return;

    this.handleAddDataToStream(this.selectedUser);
    this.agentModalForm.disable();
  }

  private handleAddDataToStream(selectedUser: User): void {
    const { countryId, cityId, languageId, referred, comissionPlanId } =
      selectedUser;
    const country = {
      label: countryId.name,
      value: countryId._id,
      icon: countryId.code,
    };
    const city = { label: cityId.name, value: cityId._id };
    const language = { label: languageId.name, value: languageId._id };
    const commissionPlan = {
      label: comissionPlanId.planName,
      value: comissionPlanId._id,
    };
    this._ngCountries.addDataToStream([country]);
    this._ngCities.addDataToStream([city]);
    this._ngLanguages.addDataToStream([language]);
    this._ngCommissionPlans.addDataToStream([commissionPlan]);
    const users =
      referred?.map((ref: any) => ({
        label: ref.referralId?.username || 'N/A',
        value: ref.referralId?._id || null,
      })) || [];
    this._ngUsers.addDataToStream(users);
  }

  public onSearchSelect(typeQuery: NgSelectQuery, term?: string): void {
    const termText = term || '';

    const actionMap = new Map<NgSelectQuery, () => void>([
      [
        NG_SELECT_QUERIES.COUNTRIES,
        () => this._ngCountries.searchTerm(termText),
      ],
      [
        NG_SELECT_QUERIES.CITIES,
        () => this.handleSearchTermForCities(termText),
      ],
      [
        NG_SELECT_QUERIES.CURRENCIES,
        () => this._ngCurrencies.searchTerm(termText),
      ],
      [
        NG_SELECT_QUERIES.LANGUAGE,
        () => this._ngLanguages.searchTerm(termText),
      ],
      [NG_SELECT_QUERIES.USERS, () => this._ngUsers.searchTerm(termText)],
      [
        NG_SELECT_QUERIES.COMISSION_PLANS,
        () => this._ngCommissionPlans.searchTerm(termText),
      ],
    ]);

    const action = actionMap.get(typeQuery);
    if (action) action();
  }

  private handleSearchTermForCities(term: string): void {
    if (!this.agentModalForm) return;
    const { countryId } = this.agentModalForm.value;
    this._ngCities.extendFilter({ countryId });
    this._ngCities.searchTerm(term);
  }

  public onScrollToEndSelect(typeQuery: NgSelectQuery): void {
    const actionMap = new Map<NgSelectQuery, () => void>([
      [NG_SELECT_QUERIES.COUNTRIES, () => this._ngCountries.scrollToEnd()],
      [NG_SELECT_QUERIES.CITIES, () => this._ngCities.scrollToEnd()],
      [NG_SELECT_QUERIES.CURRENCIES, () => this._ngCurrencies.scrollToEnd()],
      [NG_SELECT_QUERIES.LANGUAGE, () => this._ngLanguages.scrollToEnd()],
      [NG_SELECT_QUERIES.USERS, () => this._ngUsers.scrollToEnd()],
      [
        NG_SELECT_QUERIES.COMISSION_PLANS,
        () => this._ngCommissionPlans.scrollToEnd(),
      ],
    ]);

    const action = actionMap.get(typeQuery);
    if (action) action();
  }

  public onCountryChange(event: NgSelect<string>): void {
    if (!this.agentModalForm || !event) return;
    this.agentModalForm.patchValue({
      cityId: '',
      currencyId: event.currencyId,
    });

    this._ngCities.resetNgSelect();
    this._ngCities.extendFilter({ countryId: event.value });
    this._ngCities.triggerFetchData();
  }

  public closeModal(): void {
    this._bsModalService.closeModal();
  }

  public onSubmit(): void {
    if (
      !this.activeButtonAction ||
      !this.agentModalForm ||
      this.agentModalForm.invalid
    ) {
      return;
    }
    this.cleanEmptyReferred();
    const executeAction = this.handleSubmitAction(this.activeButtonAction);
    executeAction();
  }

  private handleSubmitAction(buttonAction: ButtonAction): () => void {
    const actionMap = new Map<ButtonAction, () => void>([
      [BUTTON_ACTIONS.ADD, () => this.addUser()],
      [BUTTON_ACTIONS.EDIT, () => this.editUser()],
    ]);
    return actionMap.get(buttonAction) || (() => {});
  }

  private addUser(): void {
    const {
      firstName,
      lastName,
      birthDate,
      documentNumber,
      username,
      email,
      password,
      mobileNumber,
      conventionalNumber,
      countryId,
      cityId,
      address,
      gender,
      languageId,
      comissionPlanId,
      currencyId,
      roleId,
      referred,
    } = this.agentModalForm!.value;

    const userFormData = {
      firstName,
      lastName,
      birthDate,
      documentNumber,
      username,
      email,
      password,
      mobileNumber,
      conventionalNumber,
      countryId,
      cityId,
      address,
      gender,
      languageId,
      comissionPlanId,
      currencyId,
      roleId,
      referred,
    };

    this._userService
      .createUser(userFormData)
      .subscribe({ next: () => this.afterSubmitForm() });
  }

  private editUser(): void {
    if (!this.agentModalForm || !this.selectedUser) return;

    const selectedUser = this.getSelectedUser();
    const userFormData = this.getUserFormData();

    const editedUser = getDifferingPropertiesArray(selectedUser, userFormData);
    if (!Object.keys(editedUser).length) {
      const message = this._translateService.instant('words.noChangesForm');
      this._notificationService.showNotification({
        type: 'warning',
        title: 'userManagement.users.title',
        message,
      });
      return;
    }

    const { _id } = this.selectedUser;

    this._userService
      .updateUser(_id, editedUser)
      .subscribe({ next: () => this.afterSubmitForm() });
  }

  private getSelectedUser(): object {
    const {
      firstName,
      lastName,
      birthDate,
      documentNumber,
      username,
      email,
      mobileNumber,
      conventionalNumber,
      countryId,
      cityId,
      address,
      gender,
      currencyId,
      languageId,
      comissionPlanId,
      state,
      referred,
    } = this.selectedUser!;

    const normalizedReferred = referred?.map((ref: any) => ({
      referralId: ref.referralId?._id || ref.referralId,
      percentage: ref.percentage || null,
    }));
    return {
      firstName,
      lastName,
      birthDate: birthDate ? format(new Date(birthDate), 'yyyy-MM-dd') : null,
      documentNumber,
      username,
      email,
      mobileNumber,
      conventionalNumber,
      currencyId,
      countryId: countryId._id,
      cityId: cityId._id,
      address,
      gender,
      languageId: languageId._id,
      comissionPlanId: comissionPlanId._id,
      state: state === 1 ? true : false,
      referred: normalizedReferred,
    };
  }

  private getUserFormData(): object {
    const {
      firstName,
      lastName,
      birthDate,
      documentNumber,
      username,
      email,
      mobileNumber,
      conventionalNumber,
      countryId,
      cityId,
      address,
      gender,
      languageId,
      comissionPlanId,
      currencyId,
      state,
      referred,
    } = this.agentModalForm!.value;
    return {
      firstName,
      lastName,
      birthDate,
      documentNumber,
      username,
      email,
      mobileNumber,
      conventionalNumber,
      countryId,
      cityId,
      address,
      gender,
      languageId,
      comissionPlanId,
      currencyId,
      state,
      referred,
    };
  }

  private setDefaultRoleId(): void {
    this._roleService.findRole({}).subscribe({
      next: (response) => {
        const roles = response.data;
        const agentsRole = roles.result.find(
          (role: Role) => role.name === USER_AGENT
        );
        if (agentsRole) {
          this.defaultRoleId = agentsRole._id;
          if (this.agentModalForm) {
            this.agentModalForm.patchValue({ roleId: this.defaultRoleId });
          }
        }
      },
      error: (err) => {
        console.error('Error al obtener los roles', err);
      },
    });
  }

  togglePasswordVisibility() {
    this.showPassword = !this.showPassword;
  }

  public validateKeypress(event: KeyboardEvent): void {
    const allowedKeys = ['+', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
    if (!allowedKeys.includes(event.key)) {
      event.preventDefault();
    }
  }

  public validatePaste(event: ClipboardEvent): void {
    const clipboardData = event.clipboardData?.getData('text');
    const phoneNumberPattern = /^[+0-9]*$/;

    if (clipboardData && !phoneNumberPattern.test(clipboardData)) {
      event.preventDefault();
    }
  }

  get referredControls() {
    return (this.agentModalForm?.get('referred') as FormArray).controls;
  }

  addReferred() {
    const referredGroup = this._formBuilder.group({
      referralId: [null],
      percentage: [
        { value: null, disabled: true },
        [Validators.min(0.5), Validators.max(100)],
      ],
    });
    referredGroup.get('referralId')?.valueChanges.subscribe((value) => {
      const percentageControl = referredGroup.get('percentage');
      if (value) {
        percentageControl?.enable();
      } else {
        percentageControl?.disable();
        percentageControl?.reset();
      }
    });

    (this.agentModalForm?.get('referred') as FormArray).push(referredGroup);
  }

  removeReferred(index: number) {
    if (index >= 1) {
      (this.agentModalForm?.get('referred') as FormArray).removeAt(index);
    }
  }

  private cleanEmptyReferred(): void {
    const referredFormArray = this.agentModalForm?.get('referred') as FormArray;
    for (let i = referredFormArray.length - 1; i >= 0; i--) {
      const referredGroup = referredFormArray.at(i) as FormGroup;
      const referralId = referredGroup.get('referralId')?.value;
      const percentage = referredGroup.get('percentage')?.value;
      if (!referralId && !percentage) {
        referredFormArray.removeAt(i);
      }
    }
  }

  percentageRequiredIfReferralSelected(control: FormGroup) {
    const referralId = control.get('referralId')?.value;
    const percentage = control.get('percentage');

    if (referralId && !percentage?.value) {
      return { percentageRequired: true };
    }
    return null;
  }

  private afterSubmitForm(): void {
    this._filterService.updateFilterData({});
    this.closeModal();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
