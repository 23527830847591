import { Directive, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { ControlContainer, AbstractControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Directive({
  selector: '[appRequiredAsterisk]',
})
export class RequiredAsteriskDirective implements OnInit {
  private control!: AbstractControl | null;
  private inputElement!: HTMLElement | null;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private controlContainer: ControlContainer,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.inputElement = this.getInputElement();

    if (this.inputElement) {
      this.setFormControl();
      this.listenToBlurEvent();
    }

    if (this.control) {
      this.addAsterisk();
      this.addErrorMessageListener();
    }
  }

  private getInputElement(): HTMLElement | null {
    return this.el.nativeElement.querySelector(
      'input, select, textarea, ng-select'
    );
  }

  private setFormControl(): void {
    const formControlName = this.inputElement?.getAttribute('formControlName');
    if (formControlName && this.controlContainer?.control) {
      this.control = this.controlContainer.control.get(formControlName);
    }
  }

  private addAsterisk(): void {
    const label = this.el.nativeElement.querySelector('label');
    if (label) {
      const asterisk = this.renderer.createElement('span');
      this.renderer.addClass(asterisk, 'required-asterisk');
      const text = this.renderer.createText('*');
      this.renderer.appendChild(asterisk, text);
      this.renderer.appendChild(label, asterisk);
    }
  }

  private addErrorMessageListener(): void {
    if (this.inputElement) {
      const errorMessage = this.renderer.createElement('div');
      this.renderer.addClass(errorMessage, 'required-error-message');

      const translatedText = this.translate.instant(
        'words.thisFieldIsRequired'
      );
      const text = this.renderer.createText(translatedText);
      this.renderer.appendChild(errorMessage, text);
      this.renderer.setStyle(errorMessage, 'display', 'none');

      this.renderer.appendChild(this.el.nativeElement, errorMessage);

      this.control?.statusChanges?.subscribe(() => {
        const shouldShow = this.control?.invalid && this.control?.touched;
        this.renderer.setStyle(
          errorMessage,
          'display',
          shouldShow ? 'block' : 'none'
        );
      });
    }
  }

  private listenToBlurEvent(): void {
    if (this.inputElement) {
      this.renderer.listen(this.inputElement, 'blur', () => {
        this.control?.markAsTouched();
        this.control?.updateValueAndValidity();
      });
    }
  }
}
