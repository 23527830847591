import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[restrictNegative]',
})
export class RestrictNegativeDirective {
  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent): void {
    const invalidKeys = ['-', 'e', 'E'];
    if (invalidKeys.includes(event.key)) {
      event.preventDefault();
    }
  }

  @HostListener('input', ['$event'])
  onInput(event: InputEvent): void {
    const input = event.target as HTMLInputElement;

    if (
      input.value.startsWith('-') ||
      parseFloat(input.value) < 0 ||
      isNaN(Number(input.value))
    ) {
      input.value = '';
      input.dispatchEvent(new Event('input'));
    }
  }
}
