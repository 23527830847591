import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, map, Observable, of, switchMap } from 'rxjs';
import {
  USER_SESSION,
  USER_SESSION_LOGIN,
  USER_SESSION_PRE,
} from '../../helpers/global/global.constant';
import { AuthService } from '../../services/api/auth.service';
import { UserService } from '../../services/api/user.service';
import { StorageService } from '../../services/ui/storage.service';
import { UserActions } from './auth.actions';
import { swalWithBootstrapButtons } from '../../helpers/ui/ui.constant';
import { TranslateService } from '@ngx-translate/core';
import { RESPONSE_CODES } from '../../helpers/global/auth.contants';

@Injectable()
export class AuthEffects {
  constructor(
    private actions$: Actions,
    private router: Router,
    private authService: AuthService,
    private userService: UserService,
    private storageService: StorageService,
    private translate: TranslateService
  ) {}

  loadUserSession$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.loadUserSession),
      exhaustMap(() => this.loadUserSession())
    )
  );

  loginUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.userAuthenticationRequest),
      switchMap((action) => this.loginUser(action)),
      switchMap(() => this.reloadUserSessionAfterLogin())
    )
  );

  logoutUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.userLogout),
      switchMap(() => {
        this.authService.logout();
        return [UserActions.completeUserLogout()];
      })
    )
  );

  updateUserProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.requestUserProfileUpdate),
      map((action) => {
        return action.user;
      }),
      switchMap((user) =>
        this.userService.updateProfile(user).pipe(
          map((res) =>
            UserActions.userProfileUpdateSuccess({ user: res.data })
          ),
          catchError((error) =>
            of(
              UserActions.userProfileUpdateFailure({
                message: error.message,
              })
            )
          )
        )
      )
    )
  );

  enableTwoFactorAuth$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.enableTwoFactorAuthentication),
      switchMap((action) =>
        this.userService.activateTwoFactor(action._id, action.code).pipe(
          map((res) =>
            UserActions.enableTwoFactorAuthenticationSuccess({ user: res.data })
          ),
          catchError((error) =>
            of(
              UserActions.enableTwoFactorAuthenticationFailure({
                message: `Failed to enable Two-Factor Authentication: ${error.message}`,
              })
            )
          )
        )
      )
    )
  );

  disableTwoFactorAuth$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.disableTwoFactorAuthentication),
      switchMap((action) =>
        this.userService.disableTwoFactor(action._id).pipe(
          map((res) =>
            UserActions.disableTwoFactorAuthenticationSuccess({
              user: res.data,
            })
          ),
          catchError((error) =>
            of(
              UserActions.disableTwoFactorAuthenticationFailure({
                message: `Failed to disable Two-Factor Authentication: ${error.message}`,
              })
            )
          )
        )
      )
    )
  );

  // Funciones privadas para separar lógica

  private loadUserSession() {
    const storedSession = JSON.parse(
      this.storageService.secureStorage.getItem(USER_SESSION)
    );

    if (storedSession) {
      const userType: 'cashdesk' | 'user' = storedSession.type
        ? USER_SESSION_LOGIN.CASHDESK
        : USER_SESSION_LOGIN.USER;

      const userServiceCall =
        userType === USER_SESSION_LOGIN.CASHDESK
          ? this.userService.getMeCashdesks()
          : this.userService.getMeUser();

      return userServiceCall.pipe(
        map((response) => {
          const user = response.data;
          return UserActions.userAuthenticationSuccess({ user });
        }),
        catchError((error) => {
          return of(
            UserActions.userAuthenticationFailure({
              message: `Failed to load session: ${error.message}`,
            })
          );
        })
      );
    } else {
      return of(
        UserActions.userAuthenticationFailure({
          message: 'User session not found.',
        })
      );
    }
  }

  private loginUser(
    action: ReturnType<typeof UserActions.userAuthenticationRequest>
  ) {
    const { request } = action;

    return this.authService.login(request).pipe(
      switchMap((user) => {
        if (user.data.TFA === true) {
          return this.handleTFA(user.data._id).pipe(
            map((isAuthorized) => {
              if (isAuthorized) {
                return UserActions.userAuthenticationSuccess({
                  user: user.data,
                });
              } else {
                return UserActions.userAuthenticationFailure({
                  message: this.translate.instant(
                    'profile.errorMessages.incorrectCode'
                  ),
                });
              }
            })
          );
        } else {
          return of(UserActions.userAuthenticationSuccess({ user: user.data }));
        }
      }),
      catchError((error) =>
        of(
          UserActions.userAuthenticationFailure({
            message: `Login failed: ${error.message}`,
          })
        )
      )
    );
  }

  private handleTFA(userId: string): Observable<boolean> {
    return new Observable((observer) => {
      swalWithBootstrapButtons
        .fire({
          title: this.translate.instant(
            'profile.setPassword.enterCodeVerification'
          ),
          input: 'text',
          showCancelButton: true,
          confirmButtonText: this.translate.instant(
            'profile.setPassword.buttons.authorize'
          ),
          showLoaderOnConfirm: true,
          preConfirm: async (tfaCode) => {
            if (!tfaCode) {
              swalWithBootstrapButtons.showValidationMessage(
                this.translate.instant('profile.errorMessages.incorrectCode')
              );
              return false;
            }

            return this.userService
              .verifyLogin(userId, tfaCode)
              .toPromise()
              .then((response) => {
                if (response?.statusCode === RESPONSE_CODES.SUCCESS) {
                  return true;
                } else {
                  swalWithBootstrapButtons.showValidationMessage(
                    this.translate.instant(
                      'profile.errorMessages.incorrectCode'
                    )
                  );
                  return false;
                }
              })
              .catch(() => {
                swalWithBootstrapButtons.showValidationMessage(
                  this.translate.instant('profile.errorMessages.incorrectCode')
                );
                return false;
              });
          },
          allowOutsideClick: () => !swalWithBootstrapButtons.isLoading(),
        })
        .then((result) => {
          if (result.isConfirmed) {
            observer.next(true);
          } else {
            observer.next(false);
          }
          observer.complete();
        });
    });
  }

  reloadUserSessionAfterLogin() {
    const storedSession =
      JSON.parse(this.storageService.secureStorage.getItem(USER_SESSION)) ||
      JSON.parse(this.storageService.secureStorage.getItem(USER_SESSION_PRE));

    if (storedSession) {
      const userType: 'cashdesk' | 'user' = storedSession.type
        ? USER_SESSION_LOGIN.CASHDESK
        : USER_SESSION_LOGIN.USER;

      const userServiceCall =
        userType === USER_SESSION_LOGIN.CASHDESK
          ? this.userService.getMeCashdesks()
          : this.userService.getMeUser();

      return userServiceCall.pipe(
        map((response) => {
          const user = response.data;
          this.router.navigate([``]);

          return UserActions.userAuthenticationSuccess({ user });
        }),
        catchError((error) => {
          return of(
            UserActions.userAuthenticationFailure({
              message: `Failed to load session: ${error.message}`,
            })
          );
        })
      );
    } else {
      return of(
        UserActions.userAuthenticationFailure({
          message: 'User session not found.',
        })
      );
    }
  }
}
