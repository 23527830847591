@if (playerTransferForm) {
<form [formGroup]="playerTransferForm" (ngSubmit)="onSubmit()">
  <div class="card-body">
    <div class="modal-body">
      <div class="row g-2">
        <div class="form-group col-md-12">
          <label for="currAgentId" class="form-label">
            {{ 'players.player.currAgentId' | translate }}
            <span class="text-danger">*</span>
          </label>
          <ng-select
            id="currAgentId"
            formControlName="currAgentId"
            bindLabel="label"
            bindValue="value"
            placeholder="{{ 'players.player.currAgentId' | translate }}"
            [items]="users$ | async"
            (search)="onSearchSelect(NG_SELECT_QUERIES.USERS, $event.term)"
            (clear)="onSearchSelect(NG_SELECT_QUERIES.USERS)"
            (close)="onSearchSelect(NG_SELECT_QUERIES.USERS)"
            (scrollToEnd)="onScrollToEndSelect(NG_SELECT_QUERIES.USERS)"
          ></ng-select>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="closeModal()">
      {{ 'btn.cancel' | translate }}
    </button>
    <button
      type="submit"
      class="btn btn-secondary"
      [disabled]="playerTransferForm.invalid"
    >
      {{ 'players.activePlayer.movePlayer' | translate }}
    </button>
  </div>
</form>
}
