@if (changePasswordForm) {
<form [formGroup]="changePasswordForm" (ngSubmit)="onSubmit()">
  <div [ngClass]="{ 'modal-body': isModal }">
    <div class="row g-2">
      <div class="form-group col-md-6">
        <label for="password" class="form-label">
          {{ 'players.player.password' | translate }}
          <span class="text-danger">*</span>
        </label>
        <div class="position-relative">
          <input
            [type]="showPassword ? 'text' : 'password'"
            id="password"
            formControlName="password"
            class="form-control"
            placeholder="********"
          />
          <i
            class="fa password-icon"
            [ngClass]="showPassword ? 'fa-eye' : 'fa-eye-slash'"
            (click)="
              togglePasswordVisibility(CHANGE_PASSWORD_CONSTANTS.PASSWORD_FIELD)
            "
          ></i>
        </div>
        @if
        (changePasswordForm.get(CHANGE_PASSWORD_CONSTANTS.PASSWORD_FIELD)?.invalid
        &&
        changePasswordForm.get(CHANGE_PASSWORD_CONSTANTS.PASSWORD_FIELD)?.touched)
        {
        <div class="text-danger">
          @if
          (changePasswordForm.get(CHANGE_PASSWORD_CONSTANTS.PASSWORD_FIELD)?.errors?.['required'])
          {
          <small>
            {{ 'players.player.passwordRequired' | translate }}
          </small>
          } @if
          (changePasswordForm.get(CHANGE_PASSWORD_CONSTANTS.PASSWORD_FIELD)?.errors?.['minlength'])
          {
          <small>
            {{ 'players.player.passwordMinLength' | translate }}
          </small>
          }
        </div>
        }
      </div>

      <div class="form-group col-md-6">
        <label for="confirmPassword" class="form-label">
          {{ 'players.player.confirmPassword' | translate }}
          <span class="text-danger">*</span>
        </label>
        <div class="position-relative">
          <input
            [type]="showConfirmPassword ? 'text' : 'password'"
            id="confirmPassword"
            formControlName="confirmPassword"
            class="form-control"
            placeholder="********"
          />
          <i
            class="fa password-icon"
            [ngClass]="showConfirmPassword ? 'fa-eye' : 'fa-eye-slash'"
            (click)="
              togglePasswordVisibility(
                CHANGE_PASSWORD_CONSTANTS.CONFIRM_PASSWORD_FIELD
              )
            "
          ></i>
        </div>
        @if (changePasswordForm.errors?.['mismatch'] &&
        changePasswordForm.get(CHANGE_PASSWORD_CONSTANTS.CONFIRM_PASSWORD_FIELD)?.touched)
        {
        <div class="text-danger">
          <small>
            {{ 'players.player.passwordMismatch' | translate }}
          </small>
        </div>
        }
      </div>
    </div>
  </div>
  <div class="modal-footer" [ngClass]="{ 'd-none': !isModal }">
    <button type="button" class="btn btn-danger" (click)="closeModal()">
      {{ 'btn.cancel' | translate }}
    </button>
    <button
      type="submit"
      class="btn btn-secondary"
      [disabled]="changePasswordForm.invalid"
    >
      {{ 'players.activePlayer.changePassword' | translate }}
    </button>
  </div>
</form>
}
