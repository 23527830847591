import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable, of, Subject, takeUntil } from 'rxjs';
import {
  BUTTON_ACTIONS,
  NG_SELECT_QUERIES,
} from 'src/app/core/helpers/ui/ui.constant';
import { Player } from 'src/app/core/interfaces/api/player.interface';
import { ModalWithAction } from 'src/app/core/interfaces/ui/bootstrap-modal.interface';
import {
  ButtonAction,
  NgSelect,
  NgSelectQuery,
} from 'src/app/core/interfaces/ui/ui.interface';
import { CityService } from 'src/app/core/services/api/city.service';
import { CountryService } from 'src/app/core/services/api/country.service';
import { CurrencyService } from 'src/app/core/services/api/currency.service';
import { BootstrapModalService } from 'src/app/core/services/ui/bootstrap-modal.service';
import { LanguageService } from 'src/app/core/services/api/language.service';
import { SearchNgSelectService } from 'src/app/core/services/ui/search-ng-select.service';
import { PlayerService } from 'src/app/core/services/api/player.service';
import { FilterService } from 'src/app/core/services/ui/filter.service';
import { GlobalService } from 'src/app/core/services/ui/global.service';
import {
  GENDER_OPTIONS,
  USER_TYPE,
} from 'src/app/core/helpers/global/global.constant';
import { UserService } from 'src/app/core/services/api/user.service';
import { User } from 'src/app/core/interfaces/api/user.interface';
import { phoneNumberValidator } from 'src/app/shared/validators/validatorsPhoneNumber';

@Component({
  selector: 'player-modal-filter-form',
  templateUrl: './player-modal-form.component.html',
  providers: [
    { provide: 'ngCountries', useClass: SearchNgSelectService },
    { provide: 'ngCities', useClass: SearchNgSelectService },
    { provide: 'ngCurrencies', useClass: SearchNgSelectService },
    { provide: 'ngLanguages', useClass: SearchNgSelectService },
    { provide: 'ngUsers', useClass: SearchNgSelectService },
  ],
})
export class PlayerModalFormComponent {
  public BUTTON_ACTIONS = BUTTON_ACTIONS;

  public playerForm: FormGroup | undefined = undefined;
  public activeButtonAction: ButtonAction | undefined = undefined;
  public NG_SELECT_QUERIES = NG_SELECT_QUERIES;
  public agentId: string = '';
  public currencyId: string = '';
  public username: string = '';

  public countries$: Observable<NgSelect<string>[]> = of([]);
  public cities$: Observable<NgSelect<string>[]> = of([]);
  public currencies$: Observable<NgSelect<string>[]> = of([]);
  public languages$: Observable<NgSelect<string>[]> = of([]);
  public genders$: Observable<NgSelect<string>[]> = of(GENDER_OPTIONS);
  public users$: Observable<NgSelect<string>[]> = of([]);
  private unsubscribe$: Subject<boolean> = new Subject<boolean>();
  public profile: User = {} as User;
  public USER_TYPE = USER_TYPE;

  constructor(
    @Inject('ngUsers')
    private _ngUsers: SearchNgSelectService<string>,
    @Inject('ngCountries')
    private _ngCountries: SearchNgSelectService<string>,
    private _countryService: CountryService,
    @Inject('ngCities')
    private _ngCities: SearchNgSelectService<string>,
    private _cityService: CityService,
    @Inject('ngCurrencies')
    private _ngCurrencies: SearchNgSelectService<string>,
    private _currencyService: CurrencyService,
    @Inject('ngLanguages')
    private _ngLanguages: SearchNgSelectService<string>,
    private _languageService: LanguageService,

    private _profileService: GlobalService,
    private _formBuilder: FormBuilder,
    private _bsModalService: BootstrapModalService<ModalWithAction<Player>>,
    private _playerService: PlayerService,
    private _filterService: FilterService<object>,
    private _userService: UserService
  ) {}

  ngOnInit(): void {
    this._profileService.profile
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((profile) => {
        this.agentId = profile._id;
        this.username = profile.username;
        this.currencyId = profile.currencyId?._id ?? '';
        this.profile = profile;
      });
    this.setConfigNgSelect();
    this.playerForm = this.getConfigForm();
  }

  private getConfigForm(): FormGroup {
    const isAgentOrAffiliate =
      this.profile.roleId.name === USER_TYPE.AGENT ||
      this.profile.roleId.name === USER_TYPE.AFFILIATE;
    const formConfig = {
      username: ['', Validators.required],
      password: [
        '',
        [
          Validators.required,
          Validators.minLength(6),
          Validators.maxLength(20),
        ],
      ],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      birthDate: ['', Validators.required],
      gender: [null, Validators.required],
      countryId: [null, Validators.required],
      cityId: [null, Validators.required],
      address: [''],
      languageId: [null, Validators.required],
      zipCode: [''],
      mobileNumber: ['', [phoneNumberValidator()]],
      email: [null, [Validators.required, Validators.email]],
      balance: [''],
      currencyId: [isAgentOrAffiliate ? this.profile.currencyId?._id : null],
      loyaltyProgram: [false],
      callSubscription: [true],
      pushSubscription: [true],
      messageSubscription: [true],
      mailSubscription: [true],
      agentId: [
        isAgentOrAffiliate ? this.profile._id : null,
        Validators.required,
      ],
      recordSource: ['BackOffice'],
    };
    return this._formBuilder.group(formConfig);
  }

  public onSubmit(): void {
    if (!this.playerForm || this.playerForm.invalid) {
      return;
    }

    const formValue = this.playerForm.value;
    if (
      this.profile.roleId.name === USER_TYPE.AGENT ||
      this.profile.roleId.name === USER_TYPE.AFFILIATE
    ) {
      formValue.agentId = this.profile._id;
      formValue.currencyId = this.profile.currencyId?._id;
    }

    this._playerService.createPlayer(formValue).subscribe({
      next: () => {
        this._filterService.updateFilterData({});
        this.closeModal();
      },
    });
  }

  private createPlayer(): void {
    const formValue = this.playerForm?.value;
    this._playerService.createPlayer(formValue).subscribe({
      next: () => {
        this._filterService.updateFilterData({});
        this.closeModal();
      },
    });
  }

  private setConfigNgSelect(): void {
    this._ngCurrencies.setSearchTermKey('name');
    this._ngCurrencies.setFetchDataFunction(
      this._currencyService.findCurrenciesForSelect.bind(this._currencyService)
    );
    this.currencies$ = this._ngCurrencies.getData();
    this._ngCurrencies.triggerFetchData();

    this._ngCountries.setSearchTermKey('name');
    this._ngCountries.setFetchDataFunction(
      this._countryService.findCountriesForSelect.bind(this._countryService)
    );
    this.countries$ = this._ngCountries.getData();
    this._ngCountries.triggerFetchData();

    this._ngCities.setSearchTermKey('name');
    this._ngCities.setFetchDataFunction(
      this._cityService.findCitiesForSelect.bind(this._cityService)
    );
    this.cities$ = this._ngCities.getData();

    this._ngLanguages.setSearchTermKey('name');
    this._ngLanguages.setFetchDataFunction(
      this._languageService.findLanguagesForSelect.bind(this._languageService)
    );
    this.languages$ = this._ngLanguages.getData();
    this._ngLanguages.triggerFetchData();

    this._ngUsers.setSearchTermKey('username');
    this._ngUsers.extendFilter({ type: USER_TYPE.AGENT });
    this._ngUsers.setFetchDataFunction(
      this._userService.findUsersForSelect.bind(this._userService)
    );
    this.users$ = this._ngUsers.getData();
    this._ngUsers.triggerFetchData();
  }

  public onSearchSelect(type: NgSelectQuery, term?: string): void {
    if (type === NG_SELECT_QUERIES.COUNTRY) {
      this._ngCountries.searchTerm(term || '');
    } else if (type === NG_SELECT_QUERIES.CITIES) {
      this.onSearchTermForCities(term || '');
    } else if (type === NG_SELECT_QUERIES.CURRENCIES) {
      this._ngCurrencies.searchTerm(term || '');
    } else if (type === NG_SELECT_QUERIES.LANGUAGE) {
      this._ngLanguages.searchTerm(term || '');
    } else if (type === NG_SELECT_QUERIES.USERS) {
      this._ngUsers.searchTerm(term || '');
    }
  }

  private onSearchTermForCities(term: string): void {
    if (!this.playerForm) return;
    const { countryId } = this.playerForm.value;
    this._ngCities.extendFilter({ countryId });
    this._ngCities.searchTerm(term);
  }

  public onScrollToEndSelect(typeQuery: NgSelectQuery): void {
    const actionMap = new Map<NgSelectQuery, () => void>([
      [NG_SELECT_QUERIES.COUNTRIES, () => this._ngCountries.scrollToEnd()],
      [NG_SELECT_QUERIES.CITIES, () => this._ngCities.scrollToEnd()],
      [NG_SELECT_QUERIES.CURRENCIES, () => this._ngCurrencies.scrollToEnd()],
      [NG_SELECT_QUERIES.LANGUAGE, () => this._ngLanguages.scrollToEnd()],
      [NG_SELECT_QUERIES.USERS, () => this._ngUsers.scrollToEnd()],
    ]);

    const action = actionMap.get(typeQuery);
    if (action) action();
  }

  public onCountryChange(event?: NgSelect<string>): void {
    this._ngCities.resetNgSelect();
    this.playerForm?.patchValue({ cityId: null });

    if (!event) return;

    this._ngCities.extendFilter({ countryId: event.value });
    this._ngCities.triggerFetchData();
  }
  public onUserChange(selectedAgent: User): void {
    const currencyId = selectedAgent?.currencies?.[0]?.currencyId?._id;

    if (currencyId) {
      this.playerForm?.patchValue({
        currencyId: currencyId,
      });
    } else {
      this.playerForm?.patchValue({
        currencyId: null,
      });
    }
  }

  public validateKeypress(event: KeyboardEvent): void {
    const allowedKeys = ['+', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
    if (!allowedKeys.includes(event.key)) {
      event.preventDefault();
    }
  }

  public validatePaste(event: ClipboardEvent): void {
    const clipboardData = event.clipboardData?.getData('text');
    const phoneNumberPattern = /^[+0-9]*$/;

    if (clipboardData && !phoneNumberPattern.test(clipboardData)) {
      event.preventDefault();
    }
  }

  public closeModal(): void {
    this._bsModalService.closeModal();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
