export const TOURNAMENT_MANAGEMENT_COLUMNS = [
  {
    name: 'pageManagement.tournamentManagement.totalEvents',
    prop: 'eventCount',
    width: 150,
  },
];

export const SECTION_MANAGEMENT_COLUMNS = [
  {
    name: 'pageManagement.sectionManagement.layoutName',
    prop: 'title',
    sortable: false,
  },
  {
    name: 'pageManagement.sectionManagement.createdBy',
    prop: 'createdBy.username',
    sortable: false,
  },
  {
    name: 'pageManagement.sectionManagement.updatedBy',
    prop: 'updatedBy.username',
    sortable: false,
  },
];

export const PLAYER_PAGES = {
  HOME: 'home',
  SPORT: 'sport',
  CASINO: 'casino',
};

export const PAGE_SECTIONS = {
  HERO_MULTIPLE_SLIDER: {
    layout: 'hero-multiple-slider',
    title: 'Hero Multiple Slider',
    page: PLAYER_PAGES.HOME,
  },
  CASINO_GRID: {
    layout: 'casino-grid',
    title: 'Casino Grid',
    page: PLAYER_PAGES.HOME,
  },
  BANNER_GRID: {
    layout: 'banner-grid',
    title: 'Banner Grid',
    page: PLAYER_PAGES.HOME,
  },
  CATEGORY_CARDS: {
    layout: 'category-cards',
    title: 'Category Cards',
    page: PLAYER_PAGES.HOME,
  },
  BETTING_CARDS: {
    layout: 'betting-card',
    title: 'Betting Cards',
    page: PLAYER_PAGES.HOME,
  },
  HOME_SLIDER: {
    layout: 'home-slider',
    title: 'Home Slider',
    page: PLAYER_PAGES.HOME,
  },
  REGISTER_USER: {
    layout: 'register-user',
    title: 'Register User',
    page: PLAYER_PAGES.HOME,
  },
  SPORT_LEFT_BANNER: {
    layout: 'sport-left-banner',
    title: 'Sport Left Banner',
    page: PLAYER_PAGES.SPORT,
  },
  SPORT_RIGHT_BANNER: {
    layout: 'sport-right-banner',
    title: 'Sport Right Banner',
    page: PLAYER_PAGES.SPORT,
  },
  CASINO_HERO_SLIDER: {
    layout: 'casino-hero-slider',
    title: 'Casino Hero Slider',
    page: PLAYER_PAGES.CASINO,
  },
  CASINO_LEFT_BANNER: {
    layout: 'casino-left-banner',
    title: 'Casino Left Banner',
    page: PLAYER_PAGES.CASINO,
  },
};

export const ANIMATIONS = [
  { label: 'jump', value: 'jump' },
  { label: 'spin', value: 'spin' },
  { label: 'grow', value: 'grow' },
];

export const SECTION_NG_SELECT_ICONS = [
  {
    label: 'live',
    value: 'live',
  },
  {
    label: 'casino',
    value: 'casino',
  },
  {
    label: 'curved-up-arrow',
    value: 'curved-up-arrow',
  },
  {
    label: 'betting-history',
    value: 'betting-history',
  },
  {
    label: 'today',
    value: 'today',
  },
  {
    label: 'gift-bonus',
    value: 'gift-bonus',
  },
  {
    label: 'soccer-uno',
    value: 'soccer-uno',
  },
];

export const MAX_FILE_SIZE_IN_MB = 1;
