import { createAction, props } from '@ngrx/store';
import { Theme } from '../../interfaces/ui/ui.interface';

export const loadPreviewTheme = createAction('Load Preview Theme');

export const loadPreviewThemeSuccess = createAction(
  'Load Preview Theme Success',
  props<{ theme: Theme }>()
);

export const changeTheme = createAction(
  'Change Theme',
  props<{ theme: Theme }>()
);
