export const environment = {
  production: false,

  apiUrl: 'https://backofficeapi.zgameslatam.com/v1/api',
  casinoUrl: 'https://casino-api.zgameslatam.com/v1/api',
  apiPlayerUrl: 'https://betapi.zgameslatam.com/v1/api',
  fileUrl: 'https://backofficeapi.zgameslatam.com/',
  siteUrl: 'https://bet.zgameslatam.com/',
  SECRET_KEY_HASH: 'Jf5Jhr0VECKBBjeVZdJ42FWxUeHS63RCwfuCTP2HxQ',
};
