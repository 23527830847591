export const PLAYER_TABLE_COLUMNS = [
  {
    name: 'players.player.playerId',
    prop: 'accountInfo.playerId',
    width: 125,
  },
  {
    name: 'players.player.username',
    prop: 'personalInfo.username',
    width: 250,
  },
  {
    name: 'players.player.firstName',
    prop: 'personalInfo.firstName',
  },
  {
    name: 'players.player.lastName',
    prop: 'personalInfo.lastName',
  },
  {
    name: 'players.player.documentNumber',
    prop: 'personalInfo.documentNumber',
  },
  {
    name: 'players.player.balance',
    prop: 'balanceWithCurrency',
  },
  {
    name: 'players.player.idUserAffiliate',
    prop: 'userId',
  },
  {
    name: 'players.player.usernameAgent',
    prop: 'usernameAgent',
  },
  {
    name: 'players.player.categoryClient',
    prop: 'accountInfo.category',
  },
  {
    name: 'words.city',
    prop: 'city.name',
  },
  {
    name: 'words.currency',
    prop: 'currency.name',
  },
  {
    name: 'players.player.email',
    prop: 'personalInfo.email',
  },
  {
    name: 'players.player.address',
    prop: 'personalInfo.address',
  },
  {
    name: 'players.player.mobileNumber',
    prop: 'personalInfo.mobileNumber',
  },
  {
    name: 'players.player.loginAttempts',
    prop: 'verificationAndLocks.loginAttempts',
  },
  {
    name: 'players.player.lastLoginIp',
    prop: 'lastIpAddress',
  },
  {
    name: 'words.language',
    prop: 'language.name',
  },
  {
    name: 'players.player.firstDepositDate',
    prop: 'firstTransactionDate',
  },
  {
    name: 'players.player.lastDepositDate',
    prop: 'lastTransactionDate',
  },
  {
    name: 'players.player.lastBetTransactionDate',
    prop: 'lastBetTransactionDate',
  },
  {
    name: 'players.player.lastBetGamesDate',
    prop: 'lastCasinoActivityDate',
  },
  {
    name: 'words.createdAt',
    prop: 'createdAt',
  },
  {
    name: 'words.updatedAt',
    prop: 'updatedAt',
  },
];

export const BLOCKED_PLAYER_TABLE_COLUMNS = [
  {
    name: 'players.blocked.table.playerId',
    prop: 'accountInfo.playerId',
  },
  {
    name: 'players.blocked.table.firstName',
    prop: 'personalInfo.firstName',
  },
  {
    name: 'players.blocked.table.lastName',
    prop: 'personalInfo.lastName',
  },
  {
    name: 'players.blocked.table.userName',
    prop: 'personalInfo.username',
  },
  {
    name: 'players.blocked.table.agentId',
    prop: 'userId',
  },
  {
    name: 'players.blocked.table.agentId',
    prop: 'usernameAgent',
  },
  {
    name: 'players.blocked.table.dateRegistration',
    prop: 'createdAt',
  },
  {
    name: 'players.blocked.table.blockedBy',
    prop: 'usernameBlocked',
  },
];

export const TRANSFERRED_PLAYER_TABLE_COLUMNS = [
  {
    name: 'players.transferred.table.playerId',
    prop: 'playerId.accountInfo.playerId',
  },
  {
    name: 'players.transferred.table.userName',
    prop: 'playerId.personalInfo.username',
  },
  {
    name: 'players.transferred.table.previousAgentId',
    prop: 'prevAgentId.userId',
  },
  {
    name: 'players.transferred.table.currentAgentId',
    prop: 'currAgentId.userId',
  },
  {
    name: 'players.transferred.table.transferredBy',
    prop: 'transferredById.username',
  },
];

export const ACTIVE_PLAYER_COLUMNS = [
  {
    name: 'players.activePlayer.playerId',
    prop: 'accountInfo.playerId',
  },
  {
    name: 'players.activePlayer.username',
    prop: 'personalInfo.username',
    width: 250,
  },
  {
    name: 'players.activePlayer.firstName',
    prop: 'personalInfo.firstName',
    width: 250,
  },
  {
    name: 'players.activePlayer.lastName',
    prop: 'personalInfo.lastName',
    width: 250,
  },
  {
    name: 'players.activePlayer.agentId',
    prop: 'userId',
    width: 200,
  },
  {
    name: 'players.activePlayer.usernameAgent',
    prop: 'usernameAgent',
  },
  {
    name: 'players.activePlayer.balanceWithCurrency',
    prop: 'balanceWithCurrency',
  },
  {
    name: 'players.activePlayer.mobileNumber',
    prop: 'personalInfo.mobileNumber',
  },
  {
    name: 'players.activePlayer.createdAt',
    prop: 'createdAt',
  },
];

export const IS_VERIFIED = [
  { value: true, label: 'players.activePlayer.verifyed' },
  { value: false, label: 'players.activePlayer.notVerifyed' },
];

export const PLAYER_STATE = {
  LOCKED: 1,
  UNLOCKED: 0,
};

export const PLAYER_TITLES_SIDEBAR = [
  {
    general: [
      'Player information',
      'Private player information',
      'Player verification',
      'Notes',
      'Image document',
      'Messages',
    ],
  },
  { kpi: ['KPIs Jugador', 'Saldos del jugador', 'Pago'] },
  {
    config: ['Configuración apuestas', 'Historial de límites de clientes'],
  },
];

export const FIDELITY_lEVEL_OPTIONS = [
  { value: 'none', label: 'players.player.none' },
  { value: 'bronze', label: 'players.player.bronze' },
  { value: 'silver', label: 'players.player.silver' },
  { value: 'gold', label: 'players.player.gold' },
  { value: 'vip', label: 'players.player.vip' },
];

export const LOGIN_STATUS = {
  CONNECTED: 'connected',
  DISCONNECT: 'disconnected',
};

export const STATUS_ACOUNT = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
};

export const STATUS_ACOUNT_OPTIONS = [
  { value: 1, label: 'players.player.active' },
  { value: 0, label: 'players.player.inactive' },
];

export const STATUS_PLAYER_OPTIONS = [
  { value: 'temporal', label: 'players.player.temporal' },
  { value: 'permanent', label: 'players.player.permanent' },
  { value: 'disabled', label: 'players.player.disabled' },
  { value: 'closed', label: 'players.player.closed' },
  { value: 'forbidden', label: 'players.player.forbidden' },
  { value: 'locked', label: 'players.player.locked' },
  { value: 'suspended', label: 'players.player.suspended' },
  { value: 'opened', label: 'players.player.opened' },
];

export const ALMRISK_OPTIONS = [
  { value: 'none', label: 'players.player.none' },
  { value: 'low', label: 'players.player.low' },
  { value: 'medium', label: 'players.player.medium' },
  { value: 'high', label: 'players.player.high' },
];

export const EXCLUSION_TYPE_OPTIONS = [
  { value: 'none', label: 'players.player.none' },
  { value: 'inadequate_products', label: 'players.player.inadequateProducts' },
  { value: 'uncompetitive_odds', label: 'players.player.uncompetitiveOdds' },
  { value: 'auto_exclusion', label: 'players.player.autoExclusion' },
  {
    value: 'requests_for_documents_and_verifications',
    label: 'players.player.requestsForDocumentsAndVerifications',
  },
  { value: 'account_limitations', label: 'players.player.accountLimitations' },
  { value: 'no_longer_gamble', label: 'players.player.noLongerGamble' },
  { value: 'poor_user_experience', label: 'players.player.poorUserExperience' },
  {
    value: 'lack_of_desired_payments_method',
    label: 'players.player.lackOfDesiredPaymentsMethod',
  },
  {
    value: 'limited_sportsbook_markets_available',
    label: 'players.player.limitedSportsbookMarketsAvailable',
  },
];

export const AGENT_GROUPS_SPORT_OPTIONS = [
  { value: 'halfSFM', label: 'players.player.halfSFM' },
  { value: 'additional', label: 'players.player.additional' },
  { value: 'agent', label: 'players.player.agent' },
  { value: 'arbitrageBetting', label: 'players.player.arbitrageBetting' },
  { value: 'beforeVIP', label: 'players.player.beforeVIP' },
  { value: 'betshopAgent', label: 'players.player.betshopAgent' },
  { value: 'bonusHunter', label: 'players.player.bonusHunter' },
  { value: 'botArb', label: 'players.player.botArb' },
  { value: 'casino', label: 'players.player.casino' },
  { value: 'corridor', label: 'players.player.corridor' },
  { value: 'highRisk', label: 'players.player.highRisk' },
  { value: 'lateBetting', label: 'players.player.lateBetting' },
  { value: 'lowRisk', label: 'players.player.lowRisk' },
  { value: 'negative', label: 'players.player.negative' },
  { value: 'neutral', label: 'players.player.neutral' },
  { value: 'newUser', label: 'players.player.newUser' },
  { value: 'noBonusUserr', label: 'players.player.noBonusUser' },
  { value: 'notPlaying', label: 'players.player.notPlaying' },
  { value: 'review', label: 'players.player.review' },
  { value: 'sfm', label: 'players.player.sfm' },
  { value: 'strong', label: 'players.player.strong' },
  { value: 'strongOpinion', label: 'players.player.strongOpinion' },
  { value: 'testUser', label: 'players.player.testUser' },
  { value: 'valueBet', label: 'players.player.valueBet' },
  { value: 'veryNegative', label: 'players.player.veryNegative' },
  { value: 'vip', label: 'players.player.vip' },
];

export const TAB_DETAIL_OPTIONS = {
  PERSONAL_INFO: 'personalInfo',
  PRIVATE_INFO: 'privateInfo',
  VERIFICATION: 'verification',
  DOCUMENT_IMAGE: 'documentImage',
  NOTES: 'notes',
  MESSAGES: 'messages',
};

export const PLAYER_TABS = {
  CHANGE_PASSWORD: '1',
  MOVE_PLAYER: '2',
  BLOCK_PLAYER: '3',
  NOTES: '4',
};
