<div class="modal-header">
  <h5 class="modal-title" id="modal-basic-title">
    {{ titleModal | translate }}
  </h5>
  <button type="button" class="btn-close" (click)="closeModal()"></button>
</div>

@if (userFormGroup && currenciesFormArray) {
<form
  autocomplete="off"
  class="form theme-form"
  [formGroup]="userFormGroup"
  (ngSubmit)="onSubmit()"
>
  <div class="modal-body">
    <div class="row g-2">
      <div class="col-md-12">
        <h6>{{ 'userManagement.users.personalInfo' | translate }}</h6>
      </div>

      <div class="col-md-6 col-lg-4">
        <div class="form-group">
          <label for="firstName" class="form-label">
            {{ 'userManagement.users.firstName' | translate }}
            <span class="text-danger">*</span>
          </label>
          <input
            type="text"
            id="firstName"
            formControlName="firstName"
            class="form-control"
          />
          <shared-form-field-error
            [control]="userFormGroup.get('firstName')"
            [errorMessages]="{ required: 'words.requiredField' }"
          />
        </div>
      </div>

      <div class="col-md-6 col-lg-4">
        <div class="form-group">
          <label for="lastName" class="form-label">
            {{ 'userManagement.users.lastName' | translate }}
            <span class="text-danger">*</span>
          </label>
          <input
            type="text"
            id="lastName"
            formControlName="lastName"
            class="form-control"
          />
          <shared-form-field-error
            [control]="userFormGroup.get('lastName')"
            [errorMessages]="{ required: 'words.requiredField' }"
          />
        </div>
      </div>

      <div class="col-md-6 col-lg-4">
        <div class="form-group">
          <label for="documentNumber" class="form-label">
            {{ 'userManagement.users.identificationNumber' | translate }}
            <span class="text-danger">*</span>
          </label>
          <input
            type="text"
            id="documentNumber"
            formControlName="documentNumber"
            class="form-control"
          />
          <shared-form-field-error
            [control]="userFormGroup.get('documentNumber')"
            [errorMessages]="{ required: 'words.requiredField' }"
          />
        </div>
      </div>

      <div class="col-md-6 col-lg-4">
        <div class="form-group">
          <label for="username" class="form-label">
            {{ 'userManagement.users.username' | translate }}
            <span class="text-danger">*</span>
          </label>
          <input
            type="text"
            id="username"
            formControlName="username"
            class="form-control"
          />
          <shared-form-field-error
            [control]="userFormGroup.get('username')"
            [errorMessages]="{ required: 'words.requiredField' }"
          />
        </div>
      </div>

      @if (activeButtonAction === BUTTON_ACTIONS.ADD) {
      <div class="col-md-6 col-lg-4">
        <div class="form-group">
          <label for="password" class="form-label">
            {{ 'userManagement.users.password' | translate }}
            <span class="text-danger">*</span>
          </label>
          <input
            type="password"
            id="password"
            formControlName="password"
            class="form-control"
          />
          <shared-form-field-error
            [control]="userFormGroup.get('password')"
            [errorMessages]="{ required: 'words.requiredField' }"
          />
        </div>
      </div>
      }

      <div class="col-md-6 col-lg-4">
        <div class="form-group">
          <label for="birthDate" class="form-label">
            {{ 'userManagement.users.dateOfBirth' | translate }}
            <span class="text-danger">*</span>
          </label>
          <input
            type="date"
            id="birthDate"
            formControlName="birthDate"
            class="form-control"
          />
          <shared-form-field-error
            [control]="userFormGroup.get('birthDate')"
            [errorMessages]="{ required: 'words.requiredField' }"
          />
        </div>
      </div>

      <div class="col-md-6 col-lg-4">
        <div class="form-group">
          <label for="email" class="form-label">
            {{ 'userManagement.users.email' | translate }}
            <span class="text-danger">*</span>
          </label>
          <input
            type="email"
            id="email"
            formControlName="email"
            class="form-control"
          />
          <shared-form-field-error
            [control]="userFormGroup.get('email')"
            [errorMessages]="{ required: 'words.requiredField' }"
          />
        </div>
      </div>

      <div class="col-md-6 col-lg-4">
        <div class="form-group">
          <label for="gender" class="form-label">
            {{ 'words.gender' | translate }} <span class="text-danger">*</span>
          </label>
          <ng-select
            id="gender"
            formControlName="gender"
            [items]="genders$ | async"
            [bindLabel]="'label'"
            [bindValue]="'value'"
            [notFoundText]="'words.noItemsFound' | translate"
          >
            <ng-template ng-option-tmp let-item="item">
              {{ item.label | translate }}
            </ng-template>
            <ng-template ng-label-tmp let-item="item">
              {{ item.label | translate }}
            </ng-template>
          </ng-select>
          <shared-form-field-error
            [control]="userFormGroup.get('gender')"
            [errorMessages]="{ required: 'words.requiredField' }"
          />
        </div>
      </div>

      <div class="col-md-6 col-lg-4">
        <div class="form-group">
          <label for="mobileNumber" class="form-label">
            {{ 'userManagement.users.mobileNumber' | translate }}
            <span class="text-danger">*</span>
          </label>
          <input
            type="text"
            id="mobileNumber"
            formControlName="mobileNumber"
            class="form-control"
          />
          <shared-form-field-error
            [control]="userFormGroup.get('mobileNumber')"
            [errorMessages]="{ required: 'words.requiredField' }"
          />
        </div>
      </div>

      <div class="col-md-6 col-lg-4">
        <div class="form-group">
          <label for="conventionalNumber" class="form-label">
            {{ 'userManagement.users.conventionalNumber' | translate }}
          </label>
          <input
            type="text"
            id="conventionalNumber"
            formControlName="conventionalNumber"
            class="form-control"
          />
        </div>
      </div>

      <div class="col-md-12">
        <hr class="mt-2" />
        <h6>{{ 'userManagement.users.additionalInfo' | translate }}</h6>
      </div>

      <div class="col-md-6 col-lg-4">
        <div class="form-group">
          <label for="languageId" class="form-label">
            {{ 'words.language' | translate }}
            <span class="text-danger">*</span>
          </label>
          <ng-select
            id="languageId"
            formControlName="languageId"
            [items]="languages$ | async"
            [bindLabel]="'label'"
            [bindValue]="'value'"
            [notFoundText]="'words.noItemsFound' | translate"
            (search)="onSearchSelect(NG_SELECT_QUERIES.LANGUAGE, $event.term)"
            (clear)="onSearchSelect(NG_SELECT_QUERIES.LANGUAGE)"
            (close)="onSearchSelect(NG_SELECT_QUERIES.LANGUAGE)"
            (scrollToEnd)="onScrollToEndSelect(NG_SELECT_QUERIES.LANGUAGE)"
          >
          </ng-select>
          <shared-form-field-error
            [control]="userFormGroup.get('languageId')"
            [errorMessages]="{ required: 'words.requiredField' }"
          />
        </div>
      </div>

      <div class="col-md-6 col-lg-4">
        <div class="form-group">
          <label for="currencyId" class="form-label">
            {{ 'words.currency' | translate }}
            <span class="text-danger">*</span>
          </label>
          <ng-select
            id="currencyId"
            formControlName="currencyId"
            [items]="currencies$ | async"
            [bindLabel]="'label'"
            [bindValue]="'value'"
            [notFoundText]="'words.noItemsFound' | translate"
            (search)="onSearchSelect(NG_SELECT_QUERIES.CURRENCIES, $event.term)"
            (clear)="onSearchSelect(NG_SELECT_QUERIES.CURRENCIES)"
            (close)="onSearchSelect(NG_SELECT_QUERIES.CURRENCIES)"
            (scrollToEnd)="onScrollToEndSelect(NG_SELECT_QUERIES.CURRENCIES)"
            (change)="onCurrencyChange($event)"
          >
          </ng-select>
          <shared-form-field-error
            [control]="userFormGroup.get('currencyId')"
            [errorMessages]="{ required: 'words.requiredField' }"
          />
        </div>
      </div>

      <div class="col-md-6 col-lg-4">
        <div class="form-group">
          <label for="roleId" class="form-label">
            {{ 'userManagement.users.role' | translate }}
            <span class="text-danger">*</span>
          </label>
          <ng-select
            id="roleId"
            formControlName="roleId"
            [items]="roles$ | async"
            [bindLabel]="'label'"
            [bindValue]="'value'"
            [notFoundText]="'words.noItemsFound' | translate"
            (search)="onSearchSelect(NG_SELECT_QUERIES.USER_ROLES, $event.term)"
            (clear)="onSearchSelect(NG_SELECT_QUERIES.USER_ROLES)"
            (close)="onSearchSelect(NG_SELECT_QUERIES.USER_ROLES)"
            (scrollToEnd)="onScrollToEndSelect(NG_SELECT_QUERIES.USER_ROLES)"
          >
          </ng-select>
          <shared-form-field-error
            [control]="userFormGroup.get('roleId')"
            [errorMessages]="{ required: 'words.requiredField' }"
          />
        </div>
      </div>

      <div class="col-md-6 col-lg-4">
        <div class="form-group">
          <label for="countryId" class="form-label">
            {{ 'words.country' | translate }} <span class="text-danger">*</span>
          </label>
          <ng-select
            id="countryId"
            formControlName="countryId"
            [items]="countries$ | async"
            [bindLabel]="'label'"
            [bindValue]="'value'"
            [notFoundText]="'words.noItemsFound' | translate"
            (search)="onSearchSelect(NG_SELECT_QUERIES.COUNTRIES, $event.term)"
            (clear)="onSearchSelect(NG_SELECT_QUERIES.COUNTRIES)"
            (close)="onSearchSelect(NG_SELECT_QUERIES.COUNTRIES)"
            (scrollToEnd)="onScrollToEndSelect(NG_SELECT_QUERIES.COUNTRIES)"
            (change)="onCountryChange($event)"
          >
            <ng-template ng-option-tmp let-item="item">
              @if (item.icon) {
              <i
                class="flag-icon flag-icon-{{ item.icon | lowercase }} m-r-5"
              ></i>
              }
              <span>{{ item.label }}</span>
            </ng-template>
            <ng-template ng-label-tmp let-item="item">
              @if (item.icon) {
              <i
                class="flag-icon flag-icon-{{ item.icon | lowercase }} m-r-5"
              ></i>
              }
              <span>{{ item.label }}</span>
            </ng-template>
          </ng-select>
          <shared-form-field-error
            [control]="userFormGroup.get('countryId')"
            [errorMessages]="{ required: 'words.requiredField' }"
          />
        </div>
      </div>

      <div class="col-md-6 col-lg-4">
        <div class="form-group">
          <label for="cityId" class="form-label">
            {{ 'words.city' | translate }} <span class="text-danger">*</span>
          </label>
          <ng-select
            id="cityId"
            formControlName="cityId"
            [items]="cities$ | async"
            [bindLabel]="'label'"
            [bindValue]="'value'"
            [notFoundText]="'words.noItemsFound' | translate"
            (search)="onSearchSelect(NG_SELECT_QUERIES.CITIES, $event.term)"
            (clear)="onSearchSelect(NG_SELECT_QUERIES.CITIES)"
            (close)="onSearchSelect(NG_SELECT_QUERIES.CITIES)"
            (scrollToEnd)="onScrollToEndSelect(NG_SELECT_QUERIES.CITIES)"
          >
          </ng-select>
          <shared-form-field-error
            [control]="userFormGroup.get('cityId')"
            [errorMessages]="{ required: 'words.requiredField' }"
          />
        </div>
      </div>

      <div class="col-md-6 col-lg-4">
        <div class="form-group">
          <label for="address" class="form-label">
            {{ 'userManagement.users.address' | translate }}
            <span class="text-danger">*</span>
          </label>
          <input
            type="text"
            id="address"
            formControlName="address"
            class="form-control"
          />
          <shared-form-field-error
            [control]="userFormGroup.get('address')"
            [errorMessages]="{ required: 'words.requiredField' }"
          />
        </div>
      </div>

      <div class="col-md-6 col-lg-4">
        <div class="form-group">
          <label for="marketingWebsite" class="form-label">
            {{ 'userManagement.users.marketingWebsite' | translate }}
          </label>
          <input
            type="text"
            id="marketingWebsite"
            formControlName="marketingWebsite"
            class="form-control"
          />
        </div>
      </div>

      @if (activeButtonAction !== BUTTON_ACTIONS.ADD) {

      <div class="col-md-6 col-lg-4">
        <div class="form-group">
          <label for="createdAt" class="form-label">
            {{ 'words.createdAt' | translate }}
          </label>
          <input
            type="text"
            id="createdAt"
            class="form-control"
            [disabled]="true"
            [value]="createdAt | date : FORMAT_FOR_DATES"
          />
        </div>
      </div>

      <div class="col-md-6 col-lg-4">
        <div class="form-group">
          <label for="updatedAt" class="form-label">
            {{ 'words.updatedAt' | translate }}
          </label>
          <input
            type="text"
            id="updatedAt"
            class="form-control"
            [disabled]="true"
            [value]="updatedAt | date : FORMAT_FOR_DATES"
          />
        </div>
      </div>

      <div class="col-md-6 col-lg-4">
        <div class="form-group">
          <div class="animate-chk">
            <label for="TFA" class="d-block">
              {{ 'userManagement.users.twoFactorAuthentication' | translate }}
              <input
                id="TFA"
                type="checkbox"
                class="checkbox_animated m-l-5"
                formControlName="TFA"
              />
            </label>
          </div>
        </div>
      </div>

      <div class="col-md-6 col-lg-4">
        <div class="form-group">
          <div class="animate-chk">
            <label for="state" class="d-block">
              {{ 'words.active' | translate }}
              <input
                id="state"
                type="checkbox"
                class="checkbox_animated m-l-5"
                formControlName="state"
              />
            </label>
          </div>
        </div>
      </div>
      }

      <div class="col-md-12">
        <hr class="mt-2" />
        <div class="d-flex align-items-center justify-content-between">
          <h6>{{ 'userManagement.users.currenciesConfig' | translate }}</h6>
          @if(activeButtonAction !== BUTTON_ACTIONS.VIEW) {
          <button
            type="button"
            class="btn btn-sm btn-outline-secondary mb-2"
            [ngbTooltip]="'btn.add' | translate"
            (click)="addConfigCurrency()"
          >
            <i class="fa fa-plus"></i>
          </button>
          }
        </div>
        <shared-form-field-error
          [control]="userFormGroup.get('currencies')"
          [errorMessages]="{
            duplicateCurrency: 'userManagement.users.duplicateCurrency'
          }"
        />
        <table class="table">
          <thead>
            <tr>
              <th scope="col" [style.width]="'5%'">#</th>
              <th scope="col" [style.width]="'30%'">
                {{ 'words.currency' | translate }}
                <span class="text-danger">*</span>
              </th>
              <th scope="col" [style.width]="'30%'">
                {{ 'userManagement.users.balance' | translate }}
                <span class="text-danger">*</span>
              </th>
              <th scope="col" [style.width]="'30%'">
                {{ 'userManagement.users.credit' | translate }}
                <span class="text-danger">*</span>
              </th>
              @if (activeButtonAction !== BUTTON_ACTIONS.VIEW) {
              <th scope="col" [style.width]="'5%'">
                {{ 'words.actions' | translate }}
              </th>
              }
            </tr>
          </thead>
          <tbody formArrayName="currencies">
            @for (currency of currenciesFormArray.controls; let i = $index;
            track i) {
            <tr [formGroupName]="i">
              <th scope="row" class="align-middle">
                <span>{{ i + 1 }}</span>
              </th>

              <td scope="row" class="align-middle">
                <ng-select
                  formControlName="currencyId"
                  [items]="currencies$ | async"
                  [bindLabel]="'label'"
                  [bindValue]="'value'"
                  [notFoundText]="'words.noItemsFound' | translate"
                  (search)="
                    onSearchSelect(NG_SELECT_QUERIES.CURRENCIES, $event.term)
                  "
                  (clear)="onSearchSelect(NG_SELECT_QUERIES.CURRENCIES)"
                  (close)="onSearchSelect(NG_SELECT_QUERIES.CURRENCIES)"
                  (scrollToEnd)="
                    onScrollToEndSelect(NG_SELECT_QUERIES.CURRENCIES)
                  "
                  (change)="onCurrencyConfigChange(i, $event)"
                >
                </ng-select>
                <shared-form-field-error
                  [control]="currency.get('currencyId')"
                  [errorMessages]="{ required: 'words.requiredField' }"
                />
              </td>

              <td scope="row" class="align-middle">
                <input
                  type="number"
                  formControlName="balance"
                  class="form-control"
                />
                <shared-form-field-error
                  [control]="currency.get('balance')"
                  [errorMessages]="{ required: 'words.requiredField' }"
                />
              </td>

              <td scope="row" class="align-middle">
                <input
                  type="text"
                  formControlName="credit"
                  class="form-control"
                />
                <shared-form-field-error
                  [control]="currency.get('credit')"
                  [errorMessages]="{ required: 'words.requiredField' }"
                />
              </td>

              @if (activeButtonAction !== BUTTON_ACTIONS.VIEW) {
              <th scope="row" class="align-middle">
                @if (i > 0) {
                <button
                  type="button"
                  class="btn btn-outline-danger"
                  (click)="removeConfigCurrency(i)"
                >
                  <i class="fa fa-trash"></i>
                </button>
                }
              </th>
              }
            </tr>
            }
          </tbody>
        </table>
      </div>
    </div>
  </div>

  @if (activeButtonAction !== BUTTON_ACTIONS.VIEW) {
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="closeModal()">
      {{ 'btn.cancel' | translate }}
    </button>
    <button
      type="submit"
      class="btn btn-secondary"
      [disabled]="userFormGroup.invalid"
    >
      {{ 'btn.save' | translate }}
    </button>
  </div>
  }
</form>
}
