import { ActionReducerMap } from '@ngrx/store';
import { UserState } from '../interfaces/api/user.interface';
import { LanguageState } from '../interfaces/ui/language.interface';
import { ThemeState } from '../interfaces/ui/ui.interface';
import { AuthEffects } from './auth/auth.effects';
import { authReducer } from './auth/auth.reducers';
import { LanguageEffects } from './language/language.effects';
import { languageReducer } from './language/language.reducers';
import { ThemeEffects } from './theme/theme.effects';
import { themeReducer } from './theme/theme.reducers';

export interface AppState {
  theme: Readonly<ThemeState>;
  language: Readonly<LanguageState>;
  auth: Readonly<UserState>;
}

export const ROOT_REDUCERS: ActionReducerMap<AppState> = {
  theme: themeReducer,
  language: languageReducer,
  auth: authReducer,
};

export const ROOT_EFFECTS = [ThemeEffects, LanguageEffects, AuthEffects];
