import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { exhaustMap, of, tap } from 'rxjs';
import { LOCAL_STORAGE_NAMES } from '../../helpers/global/global.constant';
import { AVAILABLE_THEMES } from '../../helpers/ui/ui.constant';
import { Theme } from '../../interfaces/ui/ui.interface';
import {
  changeTheme,
  loadPreviewTheme,
  loadPreviewThemeSuccess,
} from './theme.actions';

const THEMES: Theme[] = Object.values(AVAILABLE_THEMES);

@Injectable()
export class ThemeEffects {
  constructor(private actions$: Actions) {}

  private changeTheme$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(changeTheme),
        tap(({ theme }) => {
          localStorage.setItem(LOCAL_STORAGE_NAMES.THEME, theme);
        })
      ),
    { dispatch: false }
  );

  private loadPreviewTheme$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadPreviewTheme),
      exhaustMap(() => {
        let selectedTheme: Theme = AVAILABLE_THEMES.LIGHT_ONLY;

        const localStorageData = localStorage.getItem(
          LOCAL_STORAGE_NAMES.THEME
        );

        if (!localStorageData) {
          localStorage.setItem(LOCAL_STORAGE_NAMES.THEME, selectedTheme);
        } else if (THEMES.includes(localStorageData as Theme)) {
          selectedTheme = localStorageData as Theme;
        }

        return of(
          loadPreviewThemeSuccess({
            theme: selectedTheme,
          })
        );
      })
    )
  );
}
