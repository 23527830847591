<div class="modal-header">
  <h5 class="modal-title" id="modal-basic-title">
    {{ 'players.player.addPlayer' | translate }}
  </h5>
  <button type="button" class="btn-close" (click)="closeModal()"></button>
</div>

@if(playerForm) {
<form [formGroup]="playerForm" (ngSubmit)="onSubmit()">
  <div class="modal-body">
    <ng-container
      *ngTemplateOutlet="personalInfo; context: { form: playerForm }"
    ></ng-container>
    <ng-container
      *ngTemplateOutlet="contactInfo; context: { form: playerForm }"
    ></ng-container>
    <ng-container
      *ngTemplateOutlet="
        communicationPreferences;
        context: { form: playerForm }
      "
    ></ng-container>
    <ng-container
      *ngTemplateOutlet="otherInfo; context: { form: playerForm }"
    ></ng-container>
  </div>
  @if (activeButtonAction !== BUTTON_ACTIONS.VIEW) {
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="closeModal()">
      {{ 'btn.cancel' | translate }}
    </button>
    <button
      type="submit"
      class="btn btn-secondary"
      [disabled]="playerForm.invalid"
    >
      {{ 'btn.save' | translate }}
    </button>
  </div>
  }
</form>
}

<ng-template #personalInfo let-form="form">
  <div class="row g-2" [formGroup]="form">
    <h5 class="mb-0">{{ 'players.player.personalInfo' | translate }}</h5>
    <hr />
    <div class="form-group col-md-4" appRequiredAsterisk>
      <label for="firstName" class="form-label">
        {{ 'players.player.firstName' | translate }}
      </label>
      <input
        type="text"
        id="firstName"
        formControlName="firstName"
        class="form-control"
      />
    </div>
    <div class="form-group col-md-4" appRequiredAsterisk>
      <label for="lastName" class="form-label">
        {{ 'players.player.lastName' | translate }}
      </label>
      <input
        type="text"
        id="lastName"
        formControlName="lastName"
        class="form-control"
      />
    </div>
    <div class="form-group col-md-4" appRequiredAsterisk>
      <label for="gender" class="form-label">
        {{ 'players.player.gender' | translate }}
      </label>
      <ng-select
        id="gender"
        formControlName="gender"
        [items]="genders$ | async"
        bindLabel="label"
        bindValue="value"
        placeholder="{{ 'players.player.gender' | translate }}"
      >
        <ng-template ng-option-tmp let-item="item">
          {{ item.label | translate }}
        </ng-template>

        <ng-template ng-label-tmp let-item="item">
          {{ item.label | translate }}
        </ng-template>
      </ng-select>
    </div>
    <div class="form-group col-md-4" appRequiredAsterisk>
      <label for="username" class="form-label">
        {{ 'players.player.username' | translate }}
      </label>
      <input
        type="text"
        id="username"
        formControlName="username"
        class="form-control"
      />
    </div>
    <div class="form-group col-md-4" appRequiredAsterisk>
      <label for="password" class="form-label">
        {{ 'players.player.password' | translate }}
      </label>
      <input
        id="password"
        type="password"
        formControlName="password"
        class="form-control"
      />
      <div
        *ngIf="
          playerForm?.get('password')?.touched &&
          playerForm?.get('password')?.invalid
        "
        class="text-danger"
      >
        <small *ngIf="playerForm?.get('password')?.errors?.['required']">
          {{ 'players.player.passwordRequired' | translate }}
        </small>
        <small *ngIf="playerForm?.get('password')?.errors?.['minlength']">
          {{ 'players.player.passwordMinLength' | translate : { min: 6 } }}
        </small>
        <small *ngIf="playerForm?.get('password')?.errors?.['maxlength']">
          {{ 'players.player.passwordMaxLength' | translate : { max: 20 } }}
        </small>
      </div>
    </div>

    <div class="form-group col-md-4" appRequiredAsterisk>
      <label for="birthDate" class="form-label">
        {{ 'players.player.birthDate' | translate }}
      </label>
      <input
        type="date"
        id="birthDate"
        formControlName="birthDate"
        class="form-control"
      />
    </div>
  </div>
</ng-template>

<ng-template #contactInfo let-form="form">
  <div class="row g-2 mt-1" [formGroup]="form">
    <h5 class="mb-0">{{ 'players.player.contactInfo' | translate }}</h5>
    <hr />
    <div class="form-group col-md-4" appRequiredAsterisk>
      <label for="countryId" class="form-label">
        {{ 'words.country' | translate }}
      </label>
      <ng-select
        id="countryId"
        formControlName="countryId"
        bindLabel="label"
        bindValue="value"
        placeholder="{{ 'words.country' | translate }}"
        [items]="countries$ | async"
        (search)="onSearchSelect(NG_SELECT_QUERIES.COUNTRY, $event.term)"
        (clear)="onSearchSelect(NG_SELECT_QUERIES.COUNTRY)"
        (close)="onSearchSelect(NG_SELECT_QUERIES.COUNTRY)"
        (scrollToEnd)="onScrollToEndSelect(NG_SELECT_QUERIES.COUNTRIES)"
        (change)="onCountryChange($event)"
      >
        <ng-template ng-option-tmp let-item="item">
          @if (item.icon) {
          <i class="flag-icon flag-icon-{{ item.icon | lowercase }} m-r-5 "></i>
          }
          <span>{{ item.label }}</span>
        </ng-template>
        <ng-template ng-label-tmp let-item="item">
          @if (item.icon) {
          <i class="flag-icon flag-icon-{{ item.icon | lowercase }} m-r-5"></i>
          }
          <span>{{ item.label }}</span>
        </ng-template></ng-select
      >
    </div>
    <div class="form-group col-md-4" appRequiredAsterisk>
      <label for="cityId" class="form-label">
        {{ 'words.city' | translate }}
      </label>
      <ng-select
        id="cityId"
        formControlName="cityId"
        bindLabel="label"
        bindValue="value"
        placeholder="{{ 'words.city' | translate }}"
        [items]="cities$ | async"
        (search)="onSearchSelect(NG_SELECT_QUERIES.CITIES, $event.term)"
        (clear)="onSearchSelect(NG_SELECT_QUERIES.CITIES)"
        (close)="onSearchSelect(NG_SELECT_QUERIES.CITIES)"
        (scrollToEnd)="onScrollToEndSelect(NG_SELECT_QUERIES.CITIES)"
      ></ng-select>
    </div>
    <div class="form-group col-md-4">
      <label for="address" class="form-label">
        {{ 'players.player.address' | translate }}
      </label>
      <input
        type="text"
        id="address"
        formControlName="address"
        class="form-control"
      />
    </div>
    <div class="form-group col-md-4">
      <label for="zipCode" class="form-label">
        {{ 'players.player.zipCode' | translate }}
      </label>
      <input
        type="text"
        id="zipCode"
        formControlName="zipCode"
        class="form-control"
      />
    </div>
    <div class="col-md-6 col-lg-4">
      <div class="form-group">
        <label for="mobileNumber" class="form-label">
          {{ 'userManagement.users.mobileNumber' | translate }}
        </label>
        <input
          type="text"
          id="mobileNumber"
          formControlName="mobileNumber"
          class="form-control"
          (keypress)="validateKeypress($event)"
          (paste)="validatePaste($event)"
        />
        <shared-form-field-error
          [control]="playerForm?.get('mobileNumber')?.errors?.['invalidPhoneNumber']"
          [errorMessages]="{ required: 'words.requiredField' }"
        >
        </shared-form-field-error>

        <shared-form-field-error
          [control]="playerForm?.get('mobileNumber')"
          [errorMessages]="{
            invalidPhoneNumber: 'words.invalidPhoneNumber',
            required: 'words.requiredField'
          }"
        >
        </shared-form-field-error>
      </div>
    </div>
    <div class="col-md-6 col-lg-4">
      <div class="form-group">
        <label for="email" class="form-label">
          {{ 'userManagement.users.email' | translate }}
          <span class="text-danger">*</span>
        </label>
        <input
          type="email"
          id="email"
          formControlName="email"
          class="form-control"
        />
        <shared-form-field-error
          [control]="playerForm?.get('email')"
          [errorMessages]="{
            email: 'words.invalidEmail',
            required: 'words.requiredField'
          }"
        >
        </shared-form-field-error>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #communicationPreferences let-form="form">
  <div class="row g-2 mt-1" [formGroup]="form">
    <h5 class="mb-0">
      {{ 'players.player.communicationPreferences' | translate }}
    </h5>
    <hr />
    <div class="form-group col-md-6">
      <div class="checkbox pt-3">
        <input
          id="callSubscription"
          formControlName="callSubscription"
          type="checkbox"
          class="form-check-input"
        />
        <label for="callSubscription" class="form-check-label">
          {{ 'players.player.SubscribedToCall' | translate }}
        </label>
      </div>
      <div class="checkbox pt-3">
        <input
          id="pushSubscription"
          formControlName="pushSubscription"
          type="checkbox"
          class="form-check-input"
        />
        <label for="pushSubscription" class="form-check-label">
          {{ 'players.player.SubscribedToNotifications' | translate }}
        </label>
      </div>
      <div class="checkbox pt-3">
        <input
          id="messageSubscription"
          formControlName="messageSubscription"
          type="checkbox"
          class="form-check-input"
        />
        <label for="messageSubscription" class="form-check-label">
          {{ 'players.player.SubscribedToTextMessages' | translate }}
        </label>
      </div>
      <div class="checkbox pt-3">
        <input
          id="mailSubscription"
          formControlName="mailSubscription"
          type="checkbox"
          class="form-check-input"
        />
        <label for="mailSubscription" class="form-check-label">{{
          'players.player.SubscribedToEmail' | translate
        }}</label>
      </div>
    </div>
    <div class="form-group col-md-6" appRequiredAsterisk>
      <label for="countryId" class="form-label">
        {{ 'players.player.lenguage' | translate }}
      </label>
      <ng-select
        id="languageId"
        bindLabel="label"
        bindValue="value"
        formControlName="languageId"
        [items]="languages$ | async"
        placeholder="{{ 'players.player.lenguage' | translate }}"
        (search)="onSearchSelect(NG_SELECT_QUERIES.LANGUAGE, $event.term)"
        (clear)="onSearchSelect(NG_SELECT_QUERIES.LANGUAGE)"
        (close)="onSearchSelect(NG_SELECT_QUERIES.LANGUAGE)"
        (scrollToEnd)="onScrollToEndSelect(NG_SELECT_QUERIES.LANGUAGE)"
      ></ng-select>
    </div>
  </div>
</ng-template>

<ng-template #otherInfo let-form="form">
  <div class="row g-2 mt-1" [formGroup]="form">
    <h5 class="mb-0">
      {{ 'players.player.otherInfo' | translate }}
    </h5>
    <hr />
    @if (this.profile.roleId.name === USER_TYPE.AGENT ||
    this.profile.roleId.name === USER_TYPE.AFFILIATE) {
    <div class="form-group col-md-6">
      <label for="agentId" class="form-label">
        {{ 'players.player.agentId' | translate }}
      </label>
      <input
        id="agentId"
        class="form-control disabled"
        [value]="profile.username"
        [readonly]="true"
      />
    </div>
    } @if (this.profile.roleId.name !== USER_TYPE.AGENT &&
    this.profile.roleId.name !== USER_TYPE.AFFILIATE) {
    <div class="form-group col-md-6" appRequiredAsterisk>
      <label for="agentId" class="form-label">
        {{ 'transfer.agent' | translate }}
      </label>
      <ng-select
        id="agentId"
        bindLabel="label"
        bindValue="value"
        formControlName="agentId"
        placeholder="{{ 'transfer.placeholders.selectAgent' | translate }}"
        [items]="users$ | async"
        (change)="onUserChange($event)"
        (search)="onSearchSelect(NG_SELECT_QUERIES.USERS, $event.term)"
        (clear)="onSearchSelect(NG_SELECT_QUERIES.USERS)"
        (close)="onSearchSelect(NG_SELECT_QUERIES.USERS)"
        (scrollToEnd)="onScrollToEndSelect(NG_SELECT_QUERIES.USERS)"
      ></ng-select>
    </div>
    }
    <div class="form-group">
      <div class="checkbox pt-3">
        <input
          type="checkbox"
          id="loyaltyProgram"
          formControlName="loyaltyProgram"
          class="form-check-input"
        />
        <label for="loyaltyProgram" class="form-check-label">
          {{ 'players.player.loyaltyProgram' | translate }}
        </label>
      </div>
    </div>
  </div>
</ng-template>
