import { createReducer, on } from '@ngrx/store';
import { AVAILABLE_THEMES } from '../../helpers/ui/ui.constant';
import { ThemeState } from '../../interfaces/ui/ui.interface';
import { changeTheme, loadPreviewThemeSuccess } from './theme.actions';

const initialThemeState: ThemeState = {
  value: AVAILABLE_THEMES.LIGHT_ONLY,
};

export const themeReducer = createReducer(
  initialThemeState,
  on(loadPreviewThemeSuccess, (state, { theme }) => ({
    ...state,
    value: theme,
  })),
  on(changeTheme, (state, { theme }) => ({
    ...state,
    value: theme,
  }))
);
