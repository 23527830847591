import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { PERMITTED_VIEWS } from 'src/app/core/helpers/global/global.constant';
import {
  AVAILABLE_THEMES,
  BUTTON_ACTIONS,
} from 'src/app/core/helpers/ui/ui.constant';
import { Player } from 'src/app/core/interfaces/api/player.interface';
import { ModalWithAction } from 'src/app/core/interfaces/ui/bootstrap-modal.interface';
import { ButtonAction, Theme } from 'src/app/core/interfaces/ui/ui.interface';
import { BootstrapModalService } from 'src/app/core/services/ui/bootstrap-modal.service';
import { LayoutService } from 'src/app/core/services/ui/layout.service';
import { NavService } from 'src/app/core/services/ui/nav.service';
import { changeTheme } from 'src/app/core/states/theme/theme.actions';
import { selectTheme } from 'src/app/core/states/theme/theme.selectors';
import SwiperCore, { Autoplay, Navigation, Pagination } from 'swiper';
import { AddUsersComponent } from './elements/add-users/add-users.component';

SwiperCore.use([Navigation, Pagination, Autoplay]);
@Component({
  selector: 'shared-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  public elem: any;
  public BUTTON_ACTIONS = BUTTON_ACTIONS;
  public PERMITTED_VIEWS = PERMITTED_VIEWS;
  private currentTheme: Theme = 'light-only';

  constructor(
    public layout: LayoutService,
    public navServices: NavService,
    @Inject(DOCUMENT) private document: any,
    private _bsModalService: BootstrapModalService<ModalWithAction<Player>>,
    private _store: Store
  ) {}

  ngOnInit() {
    this.elem = document.documentElement;

    this.subscribeToTheme();
  }

  private subscribeToTheme() {
    this._store.select(selectTheme).subscribe((theme) => {
      next: {
        this.currentTheme = theme;
        document.body.className = '';

        if (this.currentTheme === AVAILABLE_THEMES.DARK_ONLY) {
          document.body.classList.add(AVAILABLE_THEMES.DARK_ONLY);
        }
      }
    });
  }

  sidebarToggle() {
    this.navServices.collapseSidebar = !this.navServices.collapseSidebar;
    this.navServices.megaMenu = false;
    this.navServices.levelMenu = false;
  }

  layoutToggle() {
    const theme =
      this.currentTheme === AVAILABLE_THEMES.LIGHT_ONLY
        ? AVAILABLE_THEMES.DARK_ONLY
        : AVAILABLE_THEMES.LIGHT_ONLY;
    this._store.dispatch(changeTheme({ theme }));
  }

  searchToggle() {
    this.navServices.search = true;
  }

  languageToggle() {
    this.navServices.language = !this.navServices.language;
  }

  toggleFullScreen() {
    this.navServices.fullScreen = !this.navServices.fullScreen;
    if (this.navServices.fullScreen) {
      if (this.elem.requestFullscreen) {
        this.elem.requestFullscreen();
      } else if (this.elem.mozRequestFullScreen) {
        /* Firefox */
        this.elem.mozRequestFullScreen();
      } else if (this.elem.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        this.elem.webkitRequestFullscreen();
      } else if (this.elem.msRequestFullscreen) {
        /* IE/Edge */
        this.elem.msRequestFullscreen();
      }
    } else {
      if (!this.document.exitFullscreen) {
        this.document.exitFullscreen();
      } else if (this.document.mozCancelFullScreen) {
        /* Firefox */
        this.document.mozCancelFullScreen();
      } else if (this.document.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        this.document.webkitExitFullscreen();
      } else if (this.document.msExitFullscreen) {
        /* IE/Edge */
        this.document.msExitFullscreen();
      }
    }
  }

  public openModal(buttonAction: ButtonAction): void {
    this._bsModalService.openModal({
      component: AddUsersComponent,
      data: { buttonAction },
    });
  }
}
